
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const inputs = this.element.querySelectorAll('input');

    inputs.forEach(el => el.addEventListener('keyup', (evt) => this.onInputChange(evt)));
  }

  onInputChange({ target: { id, name, value } }) {
    this.element.querySelectorAll(`input[name="${name}"]`).forEach(el => (el.id !== id) && (el.value = value));
  }
}