
import { Controller } from 'stimulus';
import ApexCharts from 'apexcharts';

const defaultFormatter = (value) => value.toLocaleString();
const abbreviatedFormatter = (value) => {
  if (value > 999 && value < 1_000_000) {
    return (value / 1_000).toFixed(2).toLocaleString() + 'K';
  } else if (value >= 1_000_000) {
    return (value / 1_000_000).toFixed(2).toLocaleString() + 'M';
  } else if (value <= 999) {
    return value.toLocaleString();
  }
};
const dateFormatter = (value) => new Date(value).toLocaleDateString();

export default class extends Controller {
  static targets = ['container'];
  static values = { options: Object, formatter: String };

  connect() {
    new ApexCharts(this.containerTarget, this.options).render();
  }

  get formatters() {
    return {
      abbreviatedFormatter,
      defaultFormatter,
      dateFormatter
    };
  }

  get options() {
    const options = this.optionsValue;
    if (!options.yaxis) {
      options.yaxis = {};
    }
    if (!options.yaxis.labels) {
      options.yaxis.labels = {};
    }
    options.yaxis.labels.formatter = this.formatters[options.yaxis.labels.formatterName];

    if (!options.xaxis) {
      options.xaxis = {};
    }
    if (!options.xaxis.labels) {
      options.xaxis.labels = {};
    }
    options.xaxis.labels.formatter = this.formatters[options.xaxis.labels.formatterName];

    return options;
  }
}

