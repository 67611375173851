import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['form', 'modal'];

  submitAndOpenModal() {
    this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true }));
    new Modal(this.modalTarget).show();
  }
}
