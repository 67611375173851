import { Controller } from "stimulus";
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['checkbox','output','submit','list'];

  initialize() {
    this.list = [];
  }

  connect() {
    this.count();
  }

  clickCheckbox(event){
    const hidden = document.querySelector('input[name="campaign[ordered_posts]"]');
    const clickedItem = event.target;

    if (clickedItem.checked === true) {
      this.list.push(clickedItem.id);
      this.addToCart(clickedItem);
    } else {
      this.removeFromList(clickedItem);
      this.deleteCartTableRow(document.querySelector('i[remove-id="'+clickedItem.id+'"]'));
    }
    hidden.value = this.list.join(',');
    this.count();
  }

  count() {
    this.hideShowSubmit(this.list.length);
    this.hideModalCart(this.list.length);
    this.outputTarget.textContent = `${this.list.length} items selected`;
  }

  hideShowSubmit(checkedCount) {
    if (checkedCount > 0) {
      this.submitTarget.classList.remove('d-none');
    } else {
      this.submitTarget.classList.add('d-none');
    }
  }

  hideModalCart(cartCount){
    if (cartCount === 0){
      const modalElement = document.getElementById('submit-details');
      const modal = Modal.getOrCreateInstance(modalElement);
      modal.hide();
    }
  }

  clearForm() {
    const checkedBoxes = this.checkboxTargets.filter(checkbox => checkbox.checked === true);
    checkedBoxes.map((cbx) => {
      cbx.checked = false;
    });
    document.getElementById('cart-body').replaceChildren();
    this.list = [];
    this.count();
  }

  removeFromCart(event) {
    this.deleteCartTableRow(event.target);
    const targetElement = event.target.getAttribute('remove-id');
    document.getElementById(targetElement).checked = false;
    this.removeFromList(targetElement);
    this.count();
  }


  addToCart(result) {
    const tbody = document.querySelector('tbody');
    const template = document.querySelector('#cart-row');

    const clone = template.content.cloneNode(true);
    const td = clone.querySelectorAll('td');

    td[1].appendChild(this.image(result));
    td[1].appendChild(this.item(result));
    td[2].appendChild(this.provider(result));
    td[3].appendChild(this.type(result));

    td[4].firstChild.setAttribute('remove-id',this.targetElementId(result));

    tbody.appendChild(clone);
  }

  // private

  image(result){
    const imgUrl = result.getAttribute('data-img-url');
    const img = document.createElement('IMG');
    img.setAttribute('src', imgUrl);
    img.setAttribute('class','cart-image');

    return img;
  }

  item(result){
    const text = result.getAttribute('data-text');

    return document.createTextNode(text);
  }

  provider(result){
    const provider = result.getAttribute('data-provider');

    return document.createTextNode(provider);
  }

  type(result){
    const type = result.getAttribute('data-type');

    return document.createTextNode(type);
  }

  targetElementId(result){
    const type = result.getAttribute('data-type');
    const id = result.getAttribute('data-id');
    let targetId = '';
    if (type === 'Post'){
      targetId = "campaign_posts_" + id;
    } else {
      targetId = "campaign_stories_" + id;
    }

    return targetId;
  }

  removeFromList(element){
    this.list.splice(this.list.indexOf(element.id), 1);
  }

  deleteCartTableRow(element){
    element.closest('tr').remove();
  }
}
