import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  showContainer(event) {
    this.hideContainers();
    this.findAndShowContainer(event.target);
    this.highlightButtons(event.target);
  }

  // private

  findAndShowContainer(element) {
    element.classList.add();
    const containerName = element.dataset.targetContainer;
    if (containerName === null) {
      return;
    }
    const container = this.containerTargets.find(target => target.dataset.container === containerName);
    container.classList.remove("d-none");
  }

  hideContainers() {
    this.containerTargets.forEach(container => {
      container.classList.add("d-none");
    });
  }

  highlightButtons(event) {
    const elems = event.parentElement.querySelectorAll('.btn-link');
    let index = 0;
    for (index; index < elems.length; index++) {
      elems[index].classList.add('text-muted');
    }
    event.classList.remove('text-muted');
  }
}

