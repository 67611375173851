import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  tabToggle(event) {
    const tabType = event.target.closest('a').id;
    this.outputTargets.forEach(output => {
      output.value = tabType;
    });
  }
}

