import Flatpickr from "stimulus-flatpickr";
import Rails from "rails-ujs";

import "flatpickr/dist/themes/light.css";

export default class extends Flatpickr {

  connect() {
    this.config = {
      altInput: true,
      showMonths: 1,
      onOpen: function(selectedDates, dateStr, instance) {
        instance.altInput.readOnly = true;
      },
      onClose: function(selectedDates, dateStr, instance) {
        instance.altInput.readOnly = false;
        instance.altInput.blur();
      }
    };

    super.connect();
    this.fp.clear();
  }

  change() {
    const form = this.element.closest("form");
    if (this.element.id !== 'messaging_campaign_end_date'){
      Rails.fire(form, "submit");
    }
  }

}
