import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  toggle(event) {
    if (event.target.classList.contains('sm-predefined-datepicker')){
      event.target.closest('.row').querySelectorAll('.sm-flatpickr').forEach(disableTarget => {
        disableTarget.classList.add('disabled-datepicker');
        disableTarget.value = null;
      });
      event.target.classList.remove('disabled-datepicker');

    } else if (event.target.classList.contains('sm-flatpickr')){
      const disableTarget = event.target.closest('.row').querySelector('.sm-predefined-datepicker');
      disableTarget.classList.add('disabled-datepicker');
      disableTarget.value = null;

      event.target.closest('form').querySelectorAll('.sm-flatpickr').forEach(enableTarget => {
        enableTarget.classList.remove('disabled-datepicker');
      });
    }
  }
}
