
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const radioButtons = this.element.querySelectorAll('input[type=radio]');

    radioButtons.forEach(el => el.addEventListener('change', (evt) => this.onRadioChange(evt)));
    radioButtons[0].click();
  }

  onRadioChange({ target: { value } }) {
    this.element.querySelectorAll('.message-template-form input').forEach(el => el.disabled = true);
    const templateForm = this.element.querySelector(`#message-template-form-${value}`);
    templateForm.querySelectorAll('input').forEach(el => el.disabled = false);
  }
}