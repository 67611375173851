import { Controller } from "stimulus";
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['modal'];

  connect() {
    this.popUp = new Modal(this.modalTarget);
    this.popUp.show();
  }

  hideModal() {
    this.popUp.hide();
  }
}
