
import { Controller } from "stimulus";
import ApexCharts from 'apexcharts';

export default class extends Controller {
  static targets = ["container"];
  static values = { options: Object };

  connect() {
    new ApexCharts(this.containerTarget, this.optionsValue).render();
  }
}

