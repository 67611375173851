console.log('Vite ⚡️ Ruby 1');

import Rails from "rails-ujs";
import 'bootstrap';
import '~/javascripts/stimulus';
import { Turbo } from "@hotwired/turbo-rails";

Turbo.start();
window.Rails = Rails;


