import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hide"];

  hide(){
    if (getComputedStyle(this.hideTarget).display === 'none') {
      this.hideTarget.style.display = 'block';
    } else {
      this.hideTarget.style.display = null;
    }
  }
}
