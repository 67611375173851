import { debounce } from 'lodash';
import { Controller } from 'stimulus';
import Rails from 'rails-ujs';

export default class extends Controller {
  static get targets() {
    return ['submit'];
  }

  initialize() {
    this.submit = debounce(this.submit.bind(this), 200);
  }

  submit() {
    Rails.fire(this.submitTarget, 'submit');
    // workaround as method below does not trigger a submit event Turbo relies on
    // this.submitTarget.submit();
    // see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit_event
  }
}
