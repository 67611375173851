import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {

  link(event) {
    if (event.target.closest('td').classList.contains('disabled-row')) {
      return;
    }

    if (this.data.get("path")){
      Turbo.visit(this.data.get("path"));
    }
  }

}
