import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "filterable"];

  filter() {
    const lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase();

    this.filterableTargets.forEach((el) => {
      const filterableKey = el.getAttribute("data-filter-key").toLowerCase();
      el.classList.toggle("filter-not-found", !filterableKey.includes(lowerCaseFilterTerm));
    });
  }
}
