
import { Controller } from "stimulus";
import ApexCharts from 'apexcharts';

export default class extends Controller {
  static targets = ["container"];
  static values = { options: Object };


  connect() {
    const optionsHash = this.optionsValue;
    optionsHash.dataLabels.formatter = this.percentageFormat;
    optionsHash.tooltip.y.formatter = this.percentageFormat;
    optionsHash.xaxis.labels.formatter = this.percentageFormat;

    new ApexCharts(this.containerTarget, optionsHash).render();

  }

  percentageFormat(val) {
    return parseFloat(val).toLocaleString() +'%';
  }

}

